<!-- eslint-disable vue/multi-word-component-names -->
<script>
import FiltroReservaCartaoMargens from '@/components/shared/relatorio/filtros/FiltroReservaCartaoMargens.vue'

export default {
  extends: FiltroReservaCartaoMargens,

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidadesPorUsuario().then((res) => {
        this.entidades = res
      })
    },

    carregarListaConsignatarias() {
      this.consignatariaService
        .getConsignatariaPorRelatorio('ReservaCartao')
        .then((res) => {
          this.consignatarias = res
        })
    },
  },
}
</script>
